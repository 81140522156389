import React, { Component } from "react";
import { connect } from "react-redux";
import slideoneimg from "../../src/assets/images/slideoneimg.png";
import luxurydiningroomlighting from "../../src/assets/images/luxury-dining-room-lighting.png";
import italianwoodslider from "../../src/assets/images/italian-wood-slider.jpg";
import "./HomePage.css";
import firstimgd from "../../src/assets/images/firstimgd.png";
import secondimgd from "../../src/assets/images/secondimgd.png";
import thirdimgd from "../../src/assets/images/thirdimgd.png";
import fourthimgd from "../../src/assets/images/fourthimgd.png";
import durability from "../../src/assets/images/durability.png";
import easymentainancy from "../../src/assets/images/easymentainancy.png";
import eassytoclean from "../../src/assets/images/eassytoclean.png";
import weatherresistance from "../../src/assets/images/weatherresistance.png";
import ecofriendly from "../../src/assets/images/eco-friendly.png";
import avwoodlogo from "../../src/assets/images/avwoodlogo.png";
import banner3 from "../../src/assets/images/banner3.jpg";
import banner4 from "../../src/assets/images/banner4.jpg";
import icon9 from "../../src/assets/images/icon9.png";
import icon10 from "../../src/assets/images/icon10.png";
import icon11 from "../../src/assets/images/icon11.png";
// import { faEnvelope, faPhone, faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-solid-svg-icons';
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: window.location.origin,
    };
  }

  componentDidMount() {}
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      height: "100%",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <>
        {/* header */}
        <div className="container-fluid">
          <div className="row h_top">
            <div className="col-md-6">
              <ul className="h_flist">
                <li>
                  <i className="bi bi-envelope-open-fill"></i>
                  <span className="email_p">
                    <a href="mailto:avwood@gmail.com">info@astravernici.in</a>
                  </span>
                </li>
                <li>
                  <i className="bi bi-telephone-fill"></i>
                  <span className="email_p">
                    <a href="tel:9999999999">8588885762</a>
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-md-6 social_media_col d-flex justify-content-end">
              <ul className="h_flist">
                <li>
                  <i className="bi bi-facebook"></i>
                </li>
                <li>
                  <i className="bi bi-twitter"></i>
                </li>
                <li>
                  <i className="bi bi-instagram"></i>
                </li>
                <li>
                  <i className="bi bi-linkedin"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="row logo_row">
            <div className="col-md-6 mt-1">
              <img src={avwoodlogo} alt="Example Image" className="" />
            </div>
            <div className="col-md-6 l_buttoncol p-2 d-flex justify-content-end">
              <a href="/login" className="btn btn-success">
                Login Now
              </a>
            </div>
          </div>
        </div>
        {/* end */}
        {/* Carousel */}
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={banner3}
                alt="Example Image"
                className="imgsilder"
              />
              <div className="text-block">
                <h4>Natural Wood Coating</h4>
                <p>
                  Protect and enhance the natural beauty of your wood surfaces
                  with our premium wood coatings.
                </p>
              </div>
            </div>

            <div className="carousel-item">
              <img src={slideoneimg} alt="Example Image" className="imgsilder" />
              <div className="text-block">
                <h4>Natural Wood Coating</h4>
                <p>
                  Protect and enhance the natural beauty of your wood surfaces
                  with our premium wood coatings.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={italianwoodslider}
                alt="Example Image"
                className="imgsilder"
              />
              <div className="text-block">
                <h4>Natural Wood Coating</h4>
                <p>
                  Protect and enhance the natural beauty of your wood surfaces
                  with our premium wood coatings.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={luxurydiningroomlighting}
                alt="Example Image"
                className="imgsilder"
              />
              <div className="text-block">
                <h4>Natural Wood Coating</h4>
                <p>
                  Protect and enhance the natural beauty of your wood surfaces
                  with our premium wood coatings.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={banner4} alt="Example Image" className="imgsilder" />
              <div className="text-block">
                <h4>Natural Wood Coating</h4>
                <p>
                  Protect and enhance the natural beauty of your wood surfaces
                  with our premium wood coatings.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* new container */}
        <div className="container-fluid" style={{ background: "#fff" }}>
          <div className="row">
            <div className="col-md-12 head2containercol mt-3">
              <h2>
                What <strong>we do</strong>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 icon9col">
                  <img src={icon9} alt="Example Image" className="icon9" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 icon9hcol">
                  <h3>INNOVATIONS</h3>
                  <p>
                    We have always worked and will be <br></br>
                    continuing to develop high performance <br></br>
                    wood finishing products for enhanced <br></br>
                    quality and a healthy environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 icon9col">
                  <img src={icon10} alt="Example Image" className="icon9" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 icon9hcol">
                  <h3>SUPPORT</h3>
                  <p>
                    Our helping hands with skilful technical <br></br>
                    and creative support team is always there<br></br>
                    offering optimal support in every <br></br>
                    dynamic requirements.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 icon9col">
                  <img src={icon11} alt="Example Image" className="icon9" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 icon9hcol">
                  <h3>CUSTOMIZATION</h3>
                  <p>
                    Our innovations always tends to fulfill the<br></br>
                    satisfactory need of the customers and<br></br>
                    provide the wood finishing products<br></br>
                    completely customized in vibrant colors
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 h_texth  mt-5">
              <strong> PROFESSIONAL WOOD COATINGS </strong>
              <p className="para mt-5">
                <span>Products designed to enhance every </span>
                <b>wooden surface</b>.
              </p>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                There is always the ideal product to enhance the aesthetic
                impact of wood,
                <br />
                to <b>defend</b> it and <b>protect</b> it against all
                aggressions and the passage of time.
              </p>
            </div>
          </div>
        </div>

        <div className="container mb-2">
          <div className="row">
            <div className="col-md-6 p-1">
              <div className="example">
                <img
                  src={firstimgd}
                  alt="Example Image"
                  className="img-fluid"
                  style={{ height: "300px" }}
                />
                <div className="content">
                  <div className="text">Natural Coating</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-1">
              <div className="example">
                <img
                  src={secondimgd}
                  alt="Example Image"
                  className="img-fluid"
                  style={{ height: "300px" }}
                />
                <div className="content">
                  <div className="text">Melamine Coating</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row s_row_fimg">
            <div className="col-md-6 p-1">
              <div className="example">
                <img
                  src={thirdimgd}
                  alt="Example Image"
                  className="img-fluid"
                  style={{ height: "300px" }}
                />
                <div className="content">
                  <div className="text">Polyurethane(PU) coating</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-1">
              <div className="example">
                <img
                  src={fourthimgd}
                  alt="Example Image"
                  className="img-fluid"
                  style={{ height: "300px" }}
                />
                <div className="content">
                  <div className="text">Polyester Coating</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-2">
          <div className="row">
            <div className="col-md-12 h_texth">
              <p
                className="para mt-5"
                style={{
                  color: "#000000",
                  fontSize: "20px",
                  lineHeight: "25px",
                  fontWeight: 400,
                }}
              >
                <span>
                  Our professional wood coatings are created with specific
                  commitments to the formulation and implementation of
                  innovative paints with 
                  <span style={{ fontWeight: 700 }}>
                    low environmental impact
                  </span>{" "}
                  is a qualifying choice, which combines the ability to respond
                  resolutely to the demands of users and more generally to the
                  <span style={{ fontWeight: 700 }}>
                    collective sensitivity
                  </span>{" "}
                  and needs of industry.
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid l_conh mb-2">
          <div className="row">
            <div className="col-md-12 h_texth mt-5 p-4 mb -4">
              <h2>Benefit Of Wood Painting</h2>
              <p
                className="l_conh_para mt-5"
                style={{ fontSize: "16px", marginBottom: "43px" }}
              >
                There are various advantages to painting wood, including:
              </p>
              <div className="container-fluid mb-4">
                <div className="row icn_rowl">
                  <div className="col-md-3 mt-3">
                    <img
                      src={durability}
                      alt="Example Image"
                      className="img-fluid"
                      style={{ height: "90px", width: "90px" }}
                    />
                    <h5>Durability</h5>
                  </div>
                  <div className="col-md-2 mt-3">
                    <img
                      src={easymentainancy}
                      alt="Example Image"
                      className="img-fluid"
                      style={{ height: "90px", width: "90px" }}
                    />
                    <h5>Easy Maintenance</h5>
                  </div>
                  <div className="col-md-2 mt-3">
                    <img
                      src={eassytoclean}
                      alt="Example Image"
                      className="img-fluid"
                      style={{ height: "90px", width: "90px" }}
                    />
                    <h5>Easy to Clean</h5>
                  </div>
                  <div className="col-md-2 mt-3">
                    <img
                      src={weatherresistance}
                      alt="Example Image"
                      className="img-fluid"
                      style={{ height: "90px", width: "90px" }}
                    />
                    <h5>Weather Resistance</h5>
                  </div>
                  <div className="col-md-3 mt-3">
                    <img
                      src={ecofriendly}
                      alt="Example Image"
                      className="img-fluid"
                      style={{ height: "90px", width: "90px" }}
                    />
                    <h5>Eco-friendly</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer --> */}
        <div className="container-fluid footer-container">
          <div className="row">
            <div className="col-md-5 footer-first-col">
              <h2 className="contactcol">Contact</h2>
              <p className="contact_parar">AV Wood Coating Pvt. Ltd.</p>
              <div className="row contact_row">
                <div className="col-md-1">
                  <i class="bi bi-geo-alt-fill"></i>
                </div>
                <div className="col-md-11">
                  <p>
                    AV Wood Coating ,PLOT NO. 226, HSIIDC, INDUSTRIAL ESTATE,<br></br>
                    PHASE-1, BARHI,  HARYANA-131001 , India
                  </p>
                </div>
              </div>
              <div className="row contact_row">
                <div className="col-md-1">
                  <i className="bi bi-telephone-forward-fill"></i>
                </div>
                <div className="col-md-11 ">
                  <p>+91-8588885762</p>
                </div>
              </div>
              <div className="row contact_row">
                <div className="col-md-1">
                  <i class="bi bi-envelope-fill"></i>
                </div>
                <div className="col-md-11">
                  <p>info@astravernici.in</p>
                </div>
              </div>
              <div className="row contact_row">
                <div className="col-md-1">
                  <i class="bi bi-clock-fill"></i>
                </div>
                <div className="col-md-11">
                  <p>
                    {" "}
                    10:30 AM to 6:30 PM, Monday to Saturday
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7 mt-3">
              <div className="mapswrapper">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d688.8380026338439!2d77.04204304133069!3d29.100411386529267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDA2JzAxLjMiTiA3N8KwMDInMzIuNiJF!5e1!3m2!1sen!2sin!4v1732614185579!5m2!1sen!2sin"
                  width="750"
                  height="320"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                <a
                  href="https://www.fluxpromptgenerator.net"
                  style={{ display: "none" }}
                >
                  AI Prompt Generator
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
