import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { categoryActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
// import { userTypeMasterActions } from '../../actions';
import Switch from "@material-ui/core/Switch";
import { Dropdown } from "primereact/dropdown";

class categoryMaster extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.tblResponse2 = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    // this.actionBodyTemplateSubCate = this.actionBodyTemplateSubCate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.switchToggleConfirm2 = this.switchToggleConfirm2.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    // this.openDialog2 = this.openDialog2.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitForm2 = this.submitForm2.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.resetForm2 = this.resetForm2.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onHide2 = this.onHide2.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onPage2 = this.onPage2.bind(this);
    this.onSort2 = this.onSort.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
    this.photoBodyTemplateSubCate = this.photoBodyTemplateSubCate.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.setSubCateImagePath = this.setSubCateImagePath.bind(this);

    this.state = {
      IsAdd: true,
      IsEdit: true,
      IsEdit2: true,
      first: 0,
      first2: 0,
      rows: 5,
      rows2: 5,
      sliderImage: "",
      SubCateImage: "",
      totalRecords: 0,
      totalRecords2: 0,
      globalFilter: null,
      sortOrder: "",
      sortOrder2: "",
      buttonLabel: "Submit",
      switchAction: false,
      switchAction2: false,
      formHeading: "Add Group",
      // formHeading2: "Add Sub Category Master",
      CategoryId: 0,
      MCategoryId: 0,
      SubCatId: 0,
      CategoryName: "",
      SubCategoryName: "",
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      formError: {
        CategoryName: "",
        SubCategoryName: "",
        sliderImage: [],
        SubCateImage: [],
      },
    };
  }

  uploadFiles(e) {
    e.preventDefault();
    //console.log(this.state.file)
  }
  getDropValueOfCategory() {
    let categoryName = "";
    this.props.CategoryDataList.filter((x) => {
      if (x.value === this.state.categoryId.toString()) {
        categoryName = x.label;
      }
    });

    return categoryName;
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
    ;
    this.props.SubCatefetchApi(this.tblResponse2);

    // const userId = JSON.parse(sessionStorage.getItem("UserData"));
    // this.props.getRoleDDL(userId);
    this.props.getCategoryDDL();
  }
  //category
  photoBodyTemplate(e) {
    if (e.FilePath) {
      return <img src={e.FilePath} width="50px" />;
    }
  }
  photoBodyTemplateSubCate(e) {
    if (e.SubCateFilePath) {
      return <img src={e.SubCateFilePath} width="50px" />;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.CategoryData) {
      if (prevProps.CategoryData !== this.props.CategoryData) {
        // this.props.myProp has a different value
        if (this.props.CategoryData) {
          var e = this.props.CategoryData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
    // subcate
    if (this.props.SubCategoryData) {
      if (prevProps.SubCategoryData !== this.props.SubCategoryData) {
        // this.props.myProp has a different value
        if (this.props.SubCategoryData) {
          var e = this.props.SubCategoryData;
          this.setState({
            totalRecords2: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords2 !== 0)
        this.setState({
          totalRecords2: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    ;
    this.props.inActiveRow(this.state.CategoryId, this.state.switchAction);
  };

  switchToggleConfirm2 = (e) => {
    ;
    this.props.inActiveRow2(this.state.SubCatId, this.state.switchAction2);
    this.props.onHideShowIsActive2(false);
  };
  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "CategoryName":
        if (value.length < 1) {
          IsValid = false;
          errors.CategoryName = "Please enter Category name.";
        } else errors.CategoryName = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  validateForm2(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "SubCategoryName":
        if (value.length < 1) {
          IsValid = false;
          errors.SubCategoryName = "Please enter Subcategory name.";
        } else errors.SubCategoryName = "";
        break;
      case "MCategoryId":
        if (value.length < 1) {
          IsValid = false;
          errors.MCategoryId = "Select Category Name.";
        } else errors.MCategoryId = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  //category
  submitForm(event) {
    ;
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const formData = {
        CategoryId: this.state.CategoryId,
        CategoryName: this.state.CategoryName,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.CategoryId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.CategoryId > 0 ? this.getCurrentDateMMDDYYY() : null,
        TotalRows: this.state.totalRecords,
        FilePath: this.state.sliderImage,
      };

      if (formData.CategoryId > 0) {
        this.props.updateCategory(formData);
      } else {
        this.props.createCategory(formData);
      }

      if (!this.props.showDialog) {
        this.resetForm();
      }
    }
  }
  //Subcategory
  submitForm2(event) {
    ;
    event.preventDefault();
    let isfrmvalid2 = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm2(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid2 });
        isfrmvalid2 = false;
      }
      return isfrmvalid2;
    });

    if (isfrmvalid2) {
      const formData = {
        MCategoryId: this.state.MCategoryId,
        SubCatId: this.state.SubCatId,
        SubCategoryName: this.state.SubCategoryName,
        IsActive: this.state.IsActive,
        SubCateFilePath: this.state.SubCateImage,
        CreatedDate:
          this.state.CategoryId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.CategoryId > 0 ? this.getCurrentDateMMDDYYY() : null,
        TotalRows: this.state.totalRecords,
      };

      if (formData.SubCatId > 0) {
        ;
        this.props.SubUpdateCategory(formData);
        this.props.onHideShow2(false);
      } else {
        this.props.createSubCategory(formData);
      }

      if (!this.props.showDialog2) {
        this.resetForm2();
      }
    }
  }
  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
      CategoryId: 0,
      CategoryName: "",
      IsActive: true,
      formError: {
        CategoryName: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  resetForm2() {
    this.setState({
      MCategoryId: 0,
      SubCategoryName: "",
      SubCateFilePath: "",
      IsActive: true,
      SubCateImage: "",
      formError: {
        SubCategoryName: "",
        SubCateFilePath: "",
        SubCateImage: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }
  //to lazy loadsubCate
  getLazyLoadingValuesSubCate(event) {
    this.setState({ rows2: event.rows, first2: event.first });
    this.tblResponse2.PageSize = event.rows;
    this.tblResponse2.First = event.first + 1;
    this.tblResponse2.IsActive = true;
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse2.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }
  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  onPage2(event) {
    this.props.loadingData2(true);
    setTimeout(() => {
      this.getLazyLoadingValuesSubCate(event);
      this.props.SubCatefetchApi(this.tblResponse2);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }
  onSort2(event) {
    this.props.loadingData2(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse2.SortColumn = sortField ? sortField : "";
    this.tblResponse2.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.SubCatefetchApi(this.tblResponse2);
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  onHide2() {
    this.props.onHideShow2(false);
    this.resetForm2();
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Group" });
  }
  // openDialog2() {
  //   this.props.onHideShow2(true);
  //   this.setState({ buttonLabel: "Submit", formHeading2: "Add Sub Category" });
  // }

  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  /// this use for category
  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      this.props.onHideShow(true);
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Group",
        CategoryId: rowData.CategoryId == null ? 0 : rowData.CategoryId,
        SubCate: true,
        CategoryName: rowData.CategoryName,
        IsActive: rowData.IsActive,
        CreatedDate: rowData.CreatedDate,
        sliderImage: rowData.FilePath,
      });
    };
    const switchHandleChange = (e) => {
      ;
      this.setState({
        switchAction: !rowData.IsActive,
        CategoryId: rowData.CategoryId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.CategoryId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  /// this use for SUbcategory
  // actionBodyTemplateSubCate(rowData) {
  //   const editModeSubCate = (e) => {
  //     ;
  //     this.props.onHideShow2(true);
  //     this.setState({
  //       buttonLabel: "Update",
  //       formHeading: "Update SubCategory",
  //       SubCatId: rowData.SubCatId == null ? 0 : rowData.SubCatId,
  //       SubCate: true,
  //       SubCategoryName: rowData.SubCategoryName,
  //       MCategoryId: rowData.MCategoryId.toString(),
  //       IsActive: rowData.IsActive,
  //       CreatedDate: rowData.CreatedDate,
  //       SubCateImage: rowData.SubCateFilePath,
  //     });
  //   };
  //   const switchHandleChange = (e) => {
  //     ;
  //     this.setState({
  //       switchAction2: !rowData.IsActive,
  //       SubCatId: rowData.SubCatId,
  //     });
  //     this.props.onHideShowIsActive2(true);
  //   };

  //   if (rowData) {
  //     return (
  //       <>
  //         {this.state.IsEdit2 === true ? (
  //           <div>
  //             <Switch
  //               checked={rowData.IsActive}
  //               color="primary"
  //               name={rowData.SubCatId}
  //               onChange={switchHandleChange}
  //               inputProps={{ "aria-label": "secondary checkbox" }}
  //             />
  //             <Button icon="pi pi-pencil" onClick={editModeSubCate} />
  //           </div>
  //         ) : (
  //           ""
  //         )}
  //       </>
  //     );
  //   }
  // }
  // set Category Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.sliderImage = "";
    //errors.promotionalImage="";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              sliderImage: response.Source,
              //promotionalImage: response.Source
            });
          } else if (response.ResponseCode === "400") {
            errors.sliderImage = "Only image format is accepted.";
            this.setState({
              sliderImage: [],
              //promotionalImage:[],
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  // set Category Image Path
  setSubCateImagePath(event) {
    ;
    let errors = this.state.formError;
    errors.SubCateImage = "";
    //errors.promotionalImage="";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              SubCateImage: response.Source,
              //promotionalImage: response.Source
            });
          } else if (response.ResponseCode === "400") {
            errors.SubCateImage = "Only image format is accepted.";
            this.setState({
              SubCateImage: [],
              //promotionalImage:[],
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };
    // const renderFooterSubCate = (name) => {
    //   return (
    //     <div>
    //       <Button
    //         label="No"
    //         icon="pi pi-times"
    //         onClick={() => this.props.onHideShowIsActive2(false)}
    //         className="p-button-text"
    //       />
    //       <Button
    //         label="Yes"
    //         icon="pi pi-check"
    //         onClick={this.switchToggleConfirm2}
    //         autoFocus
    //       />
    //     </div>
    //   );
    // };
    const header = (
      <div className="table-header row ">
        <div className="col-md-6 master_headhingcol">
          <h4 className="user">Group Master</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>

          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              label="Add Group"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
            <Button
              className="mr-2"
              label="Add category"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog}
            />
          )}
          {/* {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              label="Add Sub Category"
              icon="pi pi-plus-circle"
              onClick={this.openDialog2}
            />
          ) : (
            <Button
              className="mr-2"
              label="Add Sub Category"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog2}
            />
          )} */}
        </div>
      </div>
    );

    // const SubCateheader = (
    //   <div className="table-header row ">
    //     <div className="col-md-6">
    //       <h4>SubCategory Master</h4>
    //     </div>
    //   </div>
    // );
    return (
      <div className="datatable-filter-demo dash-margin">
        <ToastContainer />

        <div className="card">
          <DataTable
            value={this.props.CategoryData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column
              style={{ width: "40%" }}
              field="CategoryName"
              header="Group Name"
              sortable
            ></Column>
            <Column
              style={{ width: "40%" }}
              field="FilePath"
              id="zoomA"
              header="Image"
              body={this.photoBodyTemplate}
              sortable
            ></Column>
            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                header="Action"
                style={{ width: "20%" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "20%", display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div>
        {/* sub Category datatable 07/05/2023 by Rameshk */}
        <br></br>
        {/* <div className="card">
          <DataTable
            value={this.props.SubCategoryData}
            header={SubCateheader}
            scrollable
            responsive={true}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows2}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords2)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage2}
            onSort={this.onSort2}
            loading={this.props.loading2}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column
              style={{ width: "25%" }}
              field="CategoryName"
              header="Category Name"
              sortable
            ></Column>
            <Column
              style={{ width: "25%" }}
              field="SubCategoryName"
              header=" Sub Category Name "
              sortable
            ></Column>
            <Column
              style={{ width: "30%" }}
              field="SubCateFilePath"
              header=" Sub Category Image "
              body={this.photoBodyTemplateSubCate}
              sortable
            ></Column>

            {this.state.IsEdit2 === true ? (
              <Column
                field="Action"
                header="Action"
                style={{ width: "20%" }}
                body={this.actionBodyTemplateSubCate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "20%", display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div> */}

        {/* Addd category Popup */}

        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={false}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <form onSubmit={this.submitForm} autocomplete="off">
            <div className="row">
              <div className="col-md-8 pt-2">
                <TextField
                  onChange={this.handleChange}
                  name="CategoryName"
                  autoComplete="false"
                  value={this.state.CategoryName}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Group Name*"
                />
                {this.state.formError.CategoryName !== "" ? (
                  <div className="error">
                    {this.state.formError.CategoryName}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="fileslider col-md-4 col-xl-4">
                <FileUpload
                  name="sliderImage"
                  mode="basic"
                  url={process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"}
                  auto={true}
                  onUpload={this.setImagePath}
                  onChange={this.fileSelectedHandler}
                  onError={this.onErrorImage}
                  multiple
                  accept={this.state.SliderUploadType}
                />

                {/* <button type="button" className="btn btn-warning btn-block" onClick={this.submitForm}>Upload</button> */}
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="fileslider col-md-8 col-xl-8">
                <TextField
                  disabled
                  id="standard-basic"
                  size="small"
                  onChange={this.handleChange}
                  value={this.state.sliderImage}
                  name="shareurl"
                  variant="outlined"
                  className="width"
                  label="File Url : "
                />
                <div className="blockimgprive">
                  {this.state.sliderImage ? (
                    <div className="p-fileupload-content mt-3 p-1">
                      <div>
                        <img
                          alt=" "
                          role="presentation"
                          src={this.state.sliderImage}
                          width="150"
                          height="100"
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.state.formError.sliderImage !== null ? (
                    <div className="error">
                      {this.state.formError.sliderImage}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="fileslider col-md-2 col-xl-4">
                {this.state.IsEdit === true ? (
                  <Button label={this.state.buttonLabel} icon="pi pi-check" />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <br></br>
                Note: Fields marked as (*) are required
              </div>
            </div>
          </form>
        </Dialog>

        {/* Add Sub Category Popup */}

        {/* <Dialog
          header={this.state.formHeading2}
          modal
          dismissableMask={false}
          visible={this.props.showDialog2}
          className="popup5 "
          onHide={this.onHide2}
          style={{ overflow: "hidden" }}
        >
          <form onSubmit={this.submitForm2} autocomplete="off">
            <div className="row">
              <div className="col-md-5">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12 custom-select"
                >
                  <Dropdown
                    onChange={this.handleChange}
                    name="MCategoryId"
                    options={this.props.CategoryDataList}
                    optionLabel="label"
                    optionValue="value"
                    value={this.state.MCategoryId}
                    filter
                    className="form-control custom-select"
                    placeholder="Select Category*"
                  ></Dropdown>
                </FormControl>

                {this.state.formError.MCategoryId !== "" ? (
                  <div className="error">
                    {this.state.formError.MCategoryId}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-5">
                <TextField
                  onChange={this.handleChange}
                  name="SubCategoryName"
                  autoComplete="false"
                  value={this.state.SubCategoryName}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Sub Category Name*"
                />
                {this.state.formError.SubCategoryName !== "" ? (
                  <div className="error">
                    {this.state.formError.SubCategoryName}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="fileslider col-md-2 col-xl-2">
                <FileUpload
                  name="SubCateImage"
                  mode="basic"
                  url={process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"}
                  auto={true}
                  onUpload={this.setSubCateImagePath}
                  onChange={this.fileSelectedHandler}
                  onError={this.onErrorImage}
                  multiple
                  accept={this.state.SliderUploadType}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="fileslider col-md-5 col-xl-5">
                <TextField
                  disabled
                  id="standard-basic"
                  size="small"
                  onChange={this.handleChange}
                  value={this.state.SubCateImage}
                  name="shareurl"
                  variant="outlined"
                  className="width"
                  label="File Url : "
                />
                <div className="blockimgprive">
                  {this.state.SubCateImage ? (
                    <div className="p-fileupload-content mt-3 p-1">
                      <div>
                        <img
                          alt=" "
                          role="presentation"
                          src={this.state.SubCateImage}
                          width="150"
                          height="160"
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.state.formError.SubCateImage !== null ? (
                    <div className="error">
                      {this.state.formError.SubCateImage}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4 text-center">
                {this.state.IsEdit === true ? (
                  <Button label={this.state.buttonLabel} icon="pi pi-check" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12 text-right">
                Note: Fields marked as (*) are required
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </form>
          <br></br>
          <br></br>
        </Dialog> */}

        {/* Confirmation start --Ramesh 04/-07/2023  */}
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
        {/* 
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog2}
          modal
          style={{ width: "350px" }}
          footer={renderFooterSubCate("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive2(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction2 == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.category.loding2);
  return {
    CategoryData: state.category.items,
    SubCategoryData: state.category.subItems,
    showDialog: state.category.showDialog,
    showDialog2: state.category.showDialog2,
    loading: state.category.loding,
    loading2: state.category.loding2,
    showIsActiveDialog: state.category.showIsActiveDialog,
    showIsActiveDialog2: state.category.showIsActiveDialog2,
    CategoryDataList: state.category.categoryddl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(categoryActions.getAll(obj));
    },
    SubCatefetchApi: (obj) => {
      dispatch(categoryActions.getSubAll(obj));
    },
    createCategory: (category) =>
      dispatch(categoryActions.createCatagory(category)),
    updateCategory: (category) =>
      dispatch(categoryActions.updateCategory(category)),
    SubUpdateCategory: (category) =>
      dispatch(categoryActions.SubUpdateCategory(category)),
    createSubCategory: (category) =>
      dispatch(categoryActions.createSubCategory(category)),
    inActiveRow: (category, isActive) =>
      dispatch(categoryActions.inActiveRow(category, isActive)),
    inActiveRow2: (category, isActive) =>
      dispatch(categoryActions.inActiveRow2(category, isActive)),
    onHideShow: (isbool) => dispatch(categoryActions.onHideShow(isbool)),
    onHideShow2: (isbool) => dispatch(categoryActions.onHideShow2(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(categoryActions.onHideShowIsActive(isbool)),
    onHideShowIsActive2: (isbool) =>
      dispatch(categoryActions.onHideShowIsActive2(isbool)),
    loadingData: (isLoding) => dispatch(categoryActions.loadingData(isLoding)),
    loadingData2: (isLoding) =>
      dispatch(categoryActions.loadingData2(isLoding)),
    getCategoryDDL: (data) => dispatch(categoryActions.getCategoryDDL(data, 1)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(categoryMaster);
