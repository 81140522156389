import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import { companyActions } from "../../actions";

import { TabView, TabPanel } from "primereact/tabview";
import Switch from "@material-ui/core/Switch";
import Moment from "react-moment";
import tableRequest from "../../models/tableRequest.js";

class companyMaster extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.Fdone = this.Fdone.bind(this);
    this.onHide = this.onHide.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.setAudioPath = this.setAudioPath.bind(this);
    this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
    this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      IsEdit: true,
      IsAdd: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      confirm: false,
      buttonLabel: "Next",
      switchAction: false,
      displayConfirmation: false,
      formHeading: "Add Company",

      createDate: null,
      updatedDate: null,
      companyId: 0,
      companyName: "",
      companyDisplayName: "",
      prefix: "",
      email: "",
      mobile: "",
      websiteURL: "",
      address: "",
      companyLogo: "",
      activeIndex: 0,
      links: [],
      name: [],
      fakeMessage: "",
      FakeAudio: "",
      isActive: true,
      formError: {
        companyName: "",
        companyDisplayName: "",
        prefix: "",
        email: "",
        mobile: "",
        websiteURL: "",
        address: "",
        companyLogo: "",
        fakeMessage: "",
        FakeAudio: "",
      },
    };
  }
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        } else {
          this.setState({
            IsEdit: true,
          });
        }

        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    setTimeout(() => {
      this.props.fetchApi(this.tblResponse);
    }, 1000);

    // $('.p-column-title').on('click', function () {
    //     $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
    // });
  }

  componentDidUpdate(prevProps) {
    if (this.props.Companys) {
      if (prevProps.Companys !== this.props.Companys) {
        // this.props.myProp has a different value
        if (this.props.Companys) {
          var e = this.props.Companys;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.companyId, this.state.switchAction);
  };

  getDropValue() {
    let productName = "";
    this.props.ProductTypeData.filter((x) => {
      if (x.ProductTypeId === this.state.ProductTypeId) {
        productName = x.ProductTypeName;
      }
    });

    return productName;
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  UpdatedDtTemplate(rowData) {
    if (rowData.UpdatedDt !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>;
    } else {
    }
  }

  CreatedDtTemplate(rowData) {
    return <Moment format="D MMM YYYY">{rowData.CreatedDt}</Moment>;
  }
  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "isActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "companyName":
        if (value.length < 1) {
          IsValid = false;
          errors.companyName = "Please enter your company Name.";
        } else errors.companyName = "";
      case "companyDisplayName":
        if (value.length < 1) {
          IsValid = false;
          errors.companyDisplayName = "Please enter Product Code.";
        } else errors.companyDisplayName = "";
        break;
      case "prefix":
        if (value.length < 1) {
          IsValid = false;
          errors.prefix = "Please enter prefix";
        } else if (value.length >= 1) {
          if (!value.match(/^[A-Z].{1}$/)) {
            IsValid = false;
            errors.prefix = "Please enter valid prefix.";
          } else errors.prefix = "";
        } else errors.prefix = "";
        break;

      case "email":
        if (value < 1) {
          IsValid = false;
          errors.email = "Please enter email.";
        } else if (value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
          /* eslint-enable */
        } else errors.email = "";
        break;
      case "mobile":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.mobile = "Please enter mobile.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.mobile = "Please enter valid mobile no.";
          } else errors.mobile = "";
        } else errors.mobile = "";
        break;
      case "websiteURL":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.websiteURL = "Please enter wesite URL";
        } else errors.websiteURL = "";
        break;
      case "address":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.address = "Please enter address";
        } else errors.address = "";
        break;
      case "companyLogo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.companyLogo = "Please enter companyLogo";
        } else errors.companyLogo = "";
        break;
      case "fakeMessage":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.fakeMessage = "Please enter fakeMessage";
        } else errors.fakeMessage = "";
        break;
      case "FakeAudio":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.FakeAudio = "Please enter FakeAudio";
        } else errors.FakeAudio = "";
        break;
      default:
        break;
    }

    return IsValid;
  }
  resetForm() {
    this.setState({
      companyId: 0,
      companyName: "",
      companyDisplayName: "",
      prefix: "",
      email: "",
      mobile: "",
      websiteURL: "",
      activeIndex: 0,
      address: "",
      companyLogo: "",
      FakeAudio: "",
      links: [],
      name: [],
      fakeMessage: "",
      isActive: true,
      formError: {
        companyName: "",
        companyDisplayName: "",
        prefix: "",
        email: "",
        mobile: "",
        websiteURL: "",
        address: "",
        companyLogo: "",
        fakeMessage: "",
        FakeAudio: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      this.setState({ activeIndex: 1 });
    }

    if (isfrmvalid) {
      var SocialLinks = {};
      for (var i = 0; i < this.state.name.length; i++) {
        if (this.state.name[i].length > 0 && this.state.links[i].length > 0)
          SocialLinks[this.state.name[i]] = this.state.links[i];
      }
      const formData = {
        CompanyId: this.state.companyId,
        CompanyName: this.state.companyName,
        CompanyDisplayName: this.state.companyDisplayName,
        Prefix: this.state.prefix,
        Email: this.state.email,
        Mobile: this.state.mobile,
        WebsiteURL: this.state.websiteURL,
        Address: this.state.address,
        CompanyLogo: this.state.companyLogo,
        FakeMessage: this.state.fakeMessage,
        FakeAudio: this.state.FakeAudio,

        SocialLinks: JSON.stringify(SocialLinks),
        IsActive: this.state.isActive,
        CreatedDt:
          this.state.companyId > 0
            ? this.state.CreatedDt
            : this.getCurrentDateMMDDYYY(),
        UpdatedDt:
          this.state.companyId > 0 ? this.getCurrentDateMMDDYYY() : null,
        TotalRows: this.state.totalRecords,
      };

      if (this.state.companyId == 0) {
        this.props.createCompany(formData);
      } else {
        this.props.updateCompany(formData);
      }

      if (!this.props.showDialog) {
        this.resetForm();
      }
    }
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  photoBodyTemplate(e) {
    return <img src={e.CompanyLogo} width="50px" />;
  }
  statusBodyTemplate(rowData) {
    return (
      <Checkbox
        defaultChecked
        disabled
        checked={rowData.isActive}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    );
  }
  // set Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.companyLogo = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              companyLogo: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.companyLogo = "Only image format is accepted.";
            this.setState({
              companyLogo: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  // set Audio Path
  setAudioPath(event) {
    this.setState({
      FakeAudio: null,
    });
    let errors = this.state.formError;
    errors.FakeAudio = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              FakeAudio: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.FakeAudio = "Only audio format is accepted.";
            this.setState({
              FakeAudio: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  handlelink = (i) => (e) => {
    let links = [...this.state.links];
    links[i] = e.target.value;
    this.setState({
      links,
    });
  };
  handlename = (i) => (e) => {
    let name = [...this.state.name];
    name[i] = e.target.value;
    this.setState({
      name,
    });
  };
  handleDelete = (i) => (e) => {
    e.preventDefault();
    let links = [
      ...this.state.links.slice(0, i),
      ...this.state.links.slice(i + 1),
    ];
    let name = [
      ...this.state.name.slice(0, i),
      ...this.state.name.slice(i + 1),
    ];
    this.setState({
      links,
      name,
    });
  };
  Fdone(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      this.setState({ activeIndex: 1 });
    }
  }
  AddLink = (e) => {
    e.preventDefault();
    let links = this.state.links.concat([""]);
    let name = this.state.name.concat([""]);
    this.setState({
      links,
      name,
    });
  };
  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      let name = [];
      let links = [];
      if (rowData.SocialLinks != undefined && rowData.SocialLinks != null) {
        let socialLinks = JSON.parse(rowData.SocialLinks);

        for (let x in socialLinks) {
          name.push(x);
          links.push(socialLinks[x]);
        }
      }
      this.props.onHideShow(true);

      this.setState({
        buttonLabel: "Next",
        formHeading: "Update Company",

        companyId: rowData.CompanyId,
        companyName: rowData.CompanyName,
        companyDisplayName: rowData.CompanyDisplayName,
        prefix: rowData.Prefix,
        email: rowData.Email,
        mobile: rowData.Mobile,
        websiteURL: rowData.WebsiteURL,
        address: rowData.Address,
        name,
        links,
        companyLogo: rowData.CompanyLogo,
        fakeMessage: rowData.FakeMessage,
        FakeAudio: rowData.FakeAudio,
        isActive: rowData.IsActive,
        createdDate: rowData.CreatedDate,
      });
    };

    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        companyId: rowData.CompanyId,
      });

      this.props.onHideShowIsActive(true);
    };
    return (
      <>
        <Switch
          checked={rowData.IsActive}
          color="primary"
          name={rowData.CompanyId}
          onChange={switchHandleChange}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />

        <Button icon="pi pi-pencil" onClick={editMode} />
      </>
    );
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Next", formHeading: "Add Company" });
  }

  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row">
        <div className="col-md-6 master_headhingcol">
          <h4>Company Master</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
          {/* <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={this.openDialog} /> */}
          {this.state.IsAdd === true ? (
            <Button
              label="Add Company"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
            <Button
              label="Add Company"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog}
            />
          )}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card">
          <DataTable
            value={this.props.Companys}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="CompanyName" header="Company Name" sortable></Column>
            <Column
              field="CompanyDisplayName"
              header="Company Display Name"
              style={{ width: "270px" }}
              sortable
            ></Column>
            <Column field="Prefix" header="Prefix" sortable></Column>
            <Column
              field="CompanyLogo"
              header="Company Logo"
              style={{ width: "200px" }}
              body={this.photoBodyTemplate}
            ></Column>
            {this.state.IsEdit === true ? (
              <Column
                className="text-right"
                body={this.actionBodyTemplate}
                header="Action"
              />
            ) : (
              <Column
                body={this.actionBodyTemplate}
                style={{ display: "none" }}
                header="Action"
              />
            )}
            {/* <Column field="Action" header="Action" style={{ width: '150px' }} body={this.actionBodyTemplate}></Column> */}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={false}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <TabView
            activeIndex={this.state.activeIndex}
            onTabChange={(e) => this.setState({ activeIndex: e.index })}
          >
            <TabPanel header="Product">
              <form onSubmit={this.Fdone}>
                <div className="row">
                  <div className="col-md-6 pt-2">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      name="companyName"
                      value={this.state.companyName}
                      className="fullwidth"
                      label="Company Name*"
                    />
                    {this.state.formError.companyName !== "" ? (
                      <div className="error">
                        {this.state.formError.companyName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-2">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      name="companyDisplayName"
                      value={this.state.companyDisplayName}
                      className="fullwidth"
                      label="Company Display Name*"
                    />
                    {this.state.formError.companyDisplayName !== "" ? (
                      <div className="error">
                        {this.state.formError.companyDisplayName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="prefix"
                      value={this.state.prefix}
                      onChange={this.handleChange}
                      className="fullwidth"
                      label="Prefix*"
                    />
                    {this.state.formError.prefix !== "" ? (
                      <div className="error">{this.state.formError.prefix}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      name="email"
                      value={this.state.email}
                      className="fullwidth"
                      label="Email*"
                    />
                    {this.state.formError.email !== "" ? (
                      <div className="error">{this.state.formError.email}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      name="mobile"
                      value={this.state.mobile}
                      className="fullwidth"
                      label="Mobile Number*"
                    />
                    {this.state.formError.mobile !== "" ? (
                      <div className="error">{this.state.formError.mobile}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      name="websiteURL"
                      value={this.state.websiteURL}
                      className="fullwidth"
                      label="Website URL"
                    />
                    {this.state.formError.websiteURL !== "" ? (
                      <div className="error">
                        {this.state.formError.websiteURL}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      name="address"
                      value={this.state.address}
                      className="fullwidth"
                      label="Address*"
                    />
                    {this.state.formError.address !== "" ? (
                      <div className="error">
                        {this.state.formError.address}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      name="fakeMessage"
                      value={this.state.fakeMessage}
                      className="fullwidth"
                      label="Fake Message"
                    />
                    {this.state.formError.fakeMessage !== "" ? (
                      <div className="error">
                        {this.state.formError.fakeMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-6 pt-3 row">
                    <div className="col-8 mb-2">Upload Company Logo*</div>
                    <div className="col-4">
                      <FileUpload
                        name="companyLogo"
                        mode="basic"
                        url={
                          process.env.REACT_APP_API_URL +
                          "UploadFile/ImageUpload"
                        }
                        auto={true}
                        onUpload={this.setImagePath}
                        onError={this.onErrorImage}
                        accept="image/*"
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      {this.state.companyLogo ? (
                        <div
                          className="p-fileupload-content mt-3 p-1"
                          style={{ display: "table", border: "1px solid #ccc" }}
                        >
                          <div>
                            <img
                              alt="pro.jfif"
                              role="presentation"
                              src={this.state.companyLogo}
                              width="100"
                            />
                          </div>
                        </div>
                      ) : null}

                      {this.state.formError.companyLogo !== null ? (
                        <div className="error">
                          {this.state.formError.companyLogo}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6 pt-3 row">
                    <div className="col-8 mb-2">Upload Fake Audio File*</div>
                    <div className="col-4 mb-2">
                      <FileUpload
                        name="FakeAudio"
                        mode="basic"
                        url={
                          process.env.REACT_APP_API_URL +
                          "UploadFile/AudioUpload"
                        }
                        auto={true}
                        onUpload={this.setAudioPath}
                        onError={this.onErrorImage}
                        accept="audio/*"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      {this.state.FakeAudio ? (
                        <div
                          className="p-fileupload-content mt-3 p-1"
                          style={{ display: "table", border: "1px solid #ccc" }}
                        >
                          <div>
                            <audio controls ref="FakeAudio">
                              <source
                                src={this.state.FakeAudio}
                                onUpload={this.setAudioPath}
                              />
                            </audio>
                          </div>
                        </div>
                      ) : null}

                      {this.state.formError.FakeAudio !== null ? (
                        <div className="error">
                          {this.state.formError.FakeAudio}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6 pt-2">
                    <Checkbox
                      disabled
                      checked={this.state.isActive}
                      onClick={this.handleChange}
                      color="primary"
                      name="isActive"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <label>Is Active</label>
                  </div>

                  <div className="col-md-12 text-center">
                    <Button label={this.state.buttonLabel} icon="pi pi-check" />
                  </div>
                  <div className="col-md-12">
                    Note: Fields marked as (*) are required
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel
              header="Add Social Handles"
              disabled="!this.state.activeIndex"
            >
              {this.state.name.map((val, index) => (
                <div key={index}>
                  <div>
                    <TextField
                      style={{
                        width: "17vw",
                        marginTop: "1vw",
                        marginLeft: "2vw",
                      }}
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      value={this.state.name[index]}
                      className="smallwidth"
                      onChange={this.handlename(index)}
                      label="Handlde Name"
                    />
                    &nbsp;&nbsp;{" "}
                    <TextField
                      style={{ width: "17vw", marginTop: "1vw" }}
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      value={this.state.links[index]}
                      className="smallwidth"
                      onChange={this.handlelink(index)}
                      label="Social Link"
                    />
                    &nbsp;&nbsp;
                    <Button
                      icon="pi pi-undo"
                      style={{ marginLeft: ".5vw" }}
                      onClick={this.handleDelete(index)}
                    ></Button>
                  </div>
                </div>
              ))}

              <div className="col-md-6 pt-3">
                <Button
                  label="Stack Links"
                  style={{ marginLeft: "1vw" }}
                  iconPos="right"
                  icon="pi pi-clone"
                  onClick={this.AddLink}
                ></Button>
              </div>
              <div className="col-md-6 pt-3">
                <Button
                  label="Submit"
                  style={{ marginLeft: "20vw", marginTop: "5vh" }}
                  iconPos="right"
                  icon="pi pi-send"
                  onClick={this.submitForm}
                ></Button>
              </div>
            </TabPanel>
          </TabView>
        </Dialog>

        {/* <Dialog header="Confirmation" visible={this.props.showIsActiveDialog} modal style={{ width: '350px' }}
                    footer={renderFooter('displayConfirmation')} onHide={() => this.props.onHideShowIsActive(false)}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to {this.state.switchAction == true ? "Active" : "InActive"}?</span>
                    </div>
                </Dialog> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    Companys: state.company.items,
    showDialog: state.company.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.company.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (data) => {
      dispatch(companyActions.getAll(data));
    },
    createCompany: (company) => dispatch(companyActions.createCompany(company)),
    updateCompany: (company) => dispatch(companyActions.updateCompany(company)),
    inActiveRow: (companyId, isActive) =>
      dispatch(companyActions.inActiveRow(companyId, isActive)),
    onHideShow: (isbool) => dispatch(companyActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(companyActions.onHideShowIsActive(isbool)),
    loadingData: (isLoding) => dispatch(companyActions.loadingData(isLoding)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(companyMaster);
