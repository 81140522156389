import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { Toast } from "primereact/toast";
import { listMasterAction } from "../../actions/listMasterAction";
import { productMasterActions } from "../../actions";
import { userMasterActions } from "../../actions";
import { roleMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
// import { userTypeMasterActions } from '../../actions';
import Switch from "@material-ui/core/Switch";
import { Dropdown } from "primereact/dropdown";
// import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
// import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
// import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class listMaster extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.Availableseries = this.Availableseries.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onHidee = this.onHidee.bind(this);
    this.openDialogue = this.openDialogue.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getProductList = this.getProductList.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.getRoleList = this.getRoleList.bind(this);
    this.UploadSchemeMapping = this.UploadSchemeMapping.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onPage1 = this.onPage1.bind(this);
    this.onSort1 = this.onSort1.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.submitseries = this.submitseries.bind(this);
    this.onChangeDDL = this.onChangeDDL.bind(this);
    this.onHideError = this.onHideError.bind(this);
    this.state = {
      selectedProduct: "",
      productId: "",
      IsAdd: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      first1: 0,
      rows1: 5,
      totalRecords1: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add List Master",
      UserId: 0,
      RoleId: 0,
      //UserTypeId: '',
      Mappseriesdata: [],
      selectedProduct: null,
      SchemeCategoryData: [
        { value: "1", label: "Loyalty Type" },
        { value: "2", label: "Group ID" },
        // { value: "3", label: "UPI" },
        // { value: "4", label: "RTGS/NEFT" },
      ],
      UserRoleData: [
        { value: "5", label: "Carpainter" },
        // { value: "2", label: "Paytm" },
      ],
     

      listType: "",
      //UserTypeId: '',

      Status: "",
      loyalitypnt: "",
      listName: "",
      listValue: "",
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      isEditing: false,
      formError: {
        productId: "",
        // SchemeName: "",
        // Schemedescription: "",
        SchemeCategory: "",
        listType: "",
        // StartDate: "",
        // EndDate: "",
        //FromNo: "",
        //UserTypeId: '',
        product: "",
        //Qty: "",
        loyalitypnt: "",
        listName: "",
        listValue: "",
        RoleId: "",
      },
    };
  }

  getDropValueOfCategory() {
    let listValue = "";
    this.props.DataList.filter((x) => {
      if (x.value === this.state.listType.toString()) {
        listValue = x.label;
      }
    });

    return listValue;
  }

  componentDidMount() {
    const userDetails = sessionStorage.getItem("user");
    const userdetailsObject = JSON.parse(userDetails);
    this.setState({
      UserId: userdetailsObject.UserId,
    });
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          ;
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 7);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      StartDate: new Date(),
      EndDate: date,
    });

    this.setState({
      Mappseriesdata: this.props.Mappseries,
    });

    this.props.fetchApi(this.tblResponse);

    const userId = "2";
    this.props.getRoleDDL(userId);
    this.props.getProductDDL();
    this.props.getListDataDLL();
  }

  componentDidUpdate(prevProps) {
    if (this.props.SchememappingData) {
      if (prevProps.SchememappingData !== this.props.SchememappingData) {
        // this.props.myProp has a different value
        if (this.props.SchememappingData) {
          var e = this.props.SchememappingData;
          if (e[0]) {
            this.setState({
              totalRecords: e[0].TotalRows,
            });
          }
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (this.props.Mappseriesdata) {
      if (prevProps.Mappseriesdata !== this.props.Mappseriesdata) {
        // this.props.myProp has a different value
        if (this.props.Mappseriesdata) {
          var e = this.props.Mappseriesdata;
          this.setState({
            totalRecords1: e[0].this.props.TotalRows1,
          });
        }
      }
    } else {
      if (this.state.totalRecords1 !== 0)
        this.setState({
          totalRecords1: 0,
          first1: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.listId, this.state.switchAction);
    setTimeout(() => {
      this.props.fetchApi(this.tblResponse);
    }, 2000);
  };

  onChangeDDL(e) {
    if (e.target.name == "product") {
      this.setState({ product: e.target.value });
      if (this.state.RoleId != "") {
        this.getFromNo(e.target.value, "p");
      }
    } else if (e.target.name == "RoleId") {
      this.setState({ RoleId: e.target.value });
      if (this.state.product != "") {
        this.getFromNo(e.target.value, "r");
      }
    }
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  getRoleList(event) {
    this.setState({ UserTypeId: event.target.value });
    this.props.getRoleDDL(event.target.value);
  }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    } else {
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }
  getProductList(event) {
    this.props.getProductDDL();
  }

  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "listName":
        if (value.length < 1) {
          IsValid = false;
          errors.listName = "Please enter Loyalty type.";
        } else errors.listName = "";
        break;
      default:
        break;
    }
    return IsValid;
  }

  getDropValueOfRole() {
    let roleName = "";
    this.props.UserRoleData.filter((x) => {
      if (x.value === this.state.RoleId.toString()) {
        roleName = x.label;
      }
    });

    return roleName;
  }

  getDropValueOfSchemeCategory() {
    let SchemeCategoryId = "";
    this.state.SchemeCategoryData.filter((x) => {
      if (x.value === this.state.listType) {
        SchemeCategoryId = x.label;
      }
    });

    return SchemeCategoryId;
  }

  submitseries(event) {
    event.preventDefault();

    this.props.onHideShoww(false);
  }

  submitForm(event) {
    ;
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      const ddlValuRole = this.getDropValueOfRole();
      //const ddlValuRole = this.getDropValueOfRole();
      //const ddlValuUserType = this.getDropValueOfUserType();
      const ddlValuSchemeCategory = this.getDropValueOfSchemeCategory();
      const formData = {
        listId: this.state.listId,
        listType: this.state.listType,
      
        Status: this.state.Status,
        loyalitypnt: this.state.loyalitypnt,
        listName: this.state.listName,
        listValue: this.state.listValue,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.UserId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.UserId > 0 ? this.getCurrentDateMMDDYYY() : null,
        RoleName: ddlValuRole != null ? ddlValuRole : "",
        TotalRows: this.state.totalRecords,
      };
      if (formData.listId > 0) {
        this.props.updateScheme(formData);
        event.preventDefault();
        setTimeout(() => {
          this.props.fetchApi(this.tblResponse);
        }, 500);
      } else {
        if (this.props.SchememappingData !== null) {
        }
        this.props.CreateList(formData);
        event.preventDefault();
        setTimeout(() => {
          this.props.fetchApi(this.tblResponse);
        }, 500);
      }

      if (!this.props.showDialog) {
        this.resetForm();
      }
    }
  }

  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
     
      listId: 0,
      listName: "",
      listType: "",

      Status: "",
      loyalitypnt: "",
      listName: "",
      listValue: "",
      IsActive: true,
      formError: {
        
        listType: "",
        Status: "",
        loyalitypnt: "",
        listName: "",
        listValue: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  getLazyLoadingValues1(event) {
    this.setState({ rows1: event.rows, first1: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  onPage1(event) {}

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  UploadSchemeMapping(event) {
    event.preventDefault();
    let isfrmvalid =
      document.querySelector('input[type="file"]').files &&
      document.querySelector('input[type="file"]').files[0] != null
        ? true
        : false;
    var file = document.querySelector('input[type="file"]').files[0];

    if (isfrmvalid) {
      ;
      var data = new FormData();
      data.append("Inputfile", file);
      data.append("CreatedBy", this.state.UserId);
      this.props.UploadSchemeMapping(data, this.tblResponse);
      setTimeout(() => {
        this.resetForm();
      }, 1000);
    } else {
    }
  }

  onSort1(event) {
    //this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    //this.props.GetAvailableseries(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.resetForm();
    this.props.onHideShow(true);
    this.setState({
      buttonLabel: "Submit",
      formHeading: "Add List",
      isEditing: false,
    });
  }
  onHidee() {
    this.props.onHideShoww(false);
  }

  openDialogue() {
    this.props.onHideShoww(true);
    this.setState({ buttonLabel: "Submit", formHeadingg: "Available Series" });
    //this.props.GetAvailableseries(this.tblResponse);
  }
  onRowSelect(event) {
    //this.toast.show({ severity: 'info', summary: 'Series Selected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
    this.setState({
      FromNo: event.data.FromNo,
      ToNo: event.data.ToNo,
      Qty: event.data.ToNo - event.data.FromNo + 1,
    });
    this.onHidee();
  }
  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  onHideError() {
    this.props.onHideShowError(false);
  }

  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      this.props.onHideShow(true);
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Scheme",
        listId: rowData.listId,
        listName: rowData.listName,
        listType: rowData.listType,
        
        Status: rowData.Status,
        IsActive: rowData.IsActive,
        loyalitypnt: rowData.loyalitypnt,
        listName: rowData.listName,
        listValue: rowData.listValue,
        CreatedDate: rowData.CreatedDate,
        isEditing: true,
      });
    };
    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        listId: rowData.listId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.listId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  render() {
    const renderFooter = () => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={() => this.switchToggleConfirm()}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row my-3">
        <div className="col-md-4 master_headhingcol">
          <h4>List Master</h4>
        </div>
        <div className="col-md-4 layoutright">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>

          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              label="Add"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
            <Button
              className="mr-2"
              label="Add Scheme"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog}
            />
          )}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card mb-5">
          <DataTable
            className="mb-2"
            value={this.props.SchememappingData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="listName" header="List Name" sortable></Column>
            <Column
              field="listValue"
              header="List Type"
              sortable
            ></Column>
            {/* <Column field="RoleName" header="Applied To" sortable></Column>
            <Column
              field="loyalitypnt"
              header="Loyalty Points"
              sortable
            ></Column> */}
            <Column field="Status" header="Status" sortable></Column>
            {this.state.IsEdit === true ? (
              <Column
                className="text-right"
                field="Action"
                header="Action"
                style={{ width: "100px" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px", display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          {/* <form onSubmit={this.submitForm} autocomplete="off"> */}
          <div className="row">
            <div className="col-md-6 pt-3">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                {/* <InputLabel id="demo-simple-select-outlined-label">
                  Select Scheme Category*
                </InputLabel> */}
                {/* <Dropdown
                  disabled={this.state.isEditing}
                  appendTo={document.getElementById("root")}
                  value={this.state.listType}
                  onChange={(e) => this.handleChange(e)}
                  options={this.state.SchemeCategoryData} 
                  optionLabel="label"
                  className="form-control"
                  placeholder="Select List*"
                  name="listType"
                  filter
                /> */}

                <Dropdown
                  onChange={this.handleChange}
                  name="listType"
                  options={this.props.DataList}
                  optionLabel="label"
                  optionValue="value"
                  value={this.state.listType}
                  filter
                  className="form-control custom-select"
                  placeholder="Select List*"
                ></Dropdown>
              </FormControl>
              {this.state.formError.listType !== "" ? (
                <div className="error">{this.state.formError.listType}</div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="col-md-6 pt-3">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                <Dropdown
                  disabled={this.state.isEditing}
                  appendTo={document.getElementById("root")}
                  value={this.state.RoleId}
                  onChange={(e) => this.handleChange(e)}
                  options={this.state.UserRoleData}
                  optionValue="value"
                  optionLabel="label"
                  className="form-control"
                  placeholder="Select User Role*"
                  name="RoleId"
                  filter
                />
                
              </FormControl>
              {this.state.formError.RoleId !== "" ? (
                <div className="error">{this.state.formError.RoleId}</div>
              ) : (
                ""
              )}
            </div> */}

            <div className="col-md-6 pt-3">
              <TextField
                disabled={this.state.isEditing}
                id="standard-basic"
                size="small"
                onChange={this.handleChange}
                variant="outlined"
                name="listName"
                value={this.state.listName}
                className="fullwidth"
                label="Loyality Type*"
              />
              {this.state.formError.listName !== "" ? (
                <div className="error">{this.state.formError.listName}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6 pt-3">
              <Checkbox
                name="IsActive"
                checked={this.state.IsActive}
                onClick={this.handleChange}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />{" "}
              <label>Is Active</label>
            </div>

            {/* <div className="col-md-12 mt-3 mb-3 text-center">
                                {this.state.IsEdit === true ?
                                    <Button label={this.state.buttonLabel} icon="pi pi-check" />
                                    : ""}
                            </div> */}
            <div className="col-md-12 mt-4 text-center">
              <div className="mb-2">Please Click Below Button To Submit*</div>

              <Button
                label={this.state.buttonLabel}
                onClick={this.submitForm}
                icon="pi pi-check"
              />
            </div>

            <div className="col-md-12">
              Note: Fields marked as (*) are required
            </div>
          </div>
          {/* </form> */}
        </Dialog>
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>

        <Dialog
          header={"Failed Mappings Data"}
          modal
          dismissableMask={false}
          visible={this.props.showErrorDialog}
          style={{ width: "50vw" }}
          onHide={this.onHideError}
        >
          <div className="datatable-selection-demo">
            <div className="card">
              <Toast ref={(el) => (this.toast = el)} />

              {this.props.ErrorData ? (
                <DataTable
                  value={this.props.ErrorData}
                  scrollable
                  responsive={true}
                  dataKey="id"
                  responsiveLayout="scroll"
                >
                  <Column
                    field="ProductCode"
                    header="Product Code"
                    sortable
                  ></Column>
                  <Column field="RoleName" header="Role Name" sortable></Column>
                  <Column
                    field="LoyaltyPoints"
                    header="Loyalty Points"
                    sortable
                  ></Column>
                  <Column field="Reason" header="Reason" sortable></Column>
                </DataTable>
              ) : (
                <h4 className="text-center"> No Data Available </h4>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("list DDL", state.listMaster.datalistddl);
  //console.log("AVA");
  return {
    SchememappingData: state.loyalityscheme.items,
    UserRoleData: state.role.rollddl,
    FromNo:
      state.loyalityscheme.Mappseries != null
        ? state.loyalityscheme.Mappseries[0].FromNo
        : "",
    //UserRoleData: state.role.rollddl,
    Mappseries: state.loyalityscheme.Mappseries,
    showDialogue: state.loyalityscheme.showDialogue,
    productData: state.product.prdctddl,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.loyalityscheme.showIsActiveDialog,
    showErrorDialog: state.loyalityscheme.showErrorDialog,
    ErrorData: state.loyalityscheme.ErrorData,
    DataList: state.listMaster.datalistddl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(listMasterAction.getAll(obj));
    },
    getProductDDL: () => {
      dispatch(productMasterActions.getPrdctDDL());
    },
    GetAvailableseries: (data) => {
      dispatch(listMasterAction.GetAvailableseries(data));
    },
    CreateList: (user) => dispatch(listMasterAction.CreateList(user)),
    updateScheme: (user) => dispatch(listMasterAction.updateScheme(user)),
    inActiveRow: (listId, isActive) =>
      dispatch(listMasterAction.inActiveRow(listId, isActive)),
    onHideShow: (isbool) => dispatch(listMasterAction.onHideShow(isbool)),
    onHideShoww: (isbool) => dispatch(listMasterAction.onHideShoww(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(listMasterAction.onHideShowIsActive(isbool)),
    onHideShowError: (isbool) =>
      dispatch(listMasterAction.onHideShowError(isbool)),
    getRoleDDL: (data) => dispatch(userMasterActions.getRoleDDL(data)),
    loadingData: (isLoding) => dispatch(listMasterAction.loadingData(isLoding)),
    UploadSchemeMapping: (obj, tblResponse) => {
      dispatch(listMasterAction.UploadSchemeMapping(obj, tblResponse));
    },
    // loadingData2: (isLoding) =>
    //   dispatch(listMasterAction.loadingData2(isLoding)),
    getListDataDLL: (data) => dispatch(listMasterAction.getListDataDLL(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(listMaster);
