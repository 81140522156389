import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { offerMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
import Switch from "@material-ui/core/Switch";
import { CSVLink } from "react-csv";

class offerMaster extends Component {
  constructor(props) {
    super(props);
    this.tblResponseExport = new tableRequest();
    this.tblResponse = new tableRequest();
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setImagePath = this.setImagePath.bind(this);

    this.state = {
      IsAdd: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add Offer",
      OfferId: 0,
      OfferName: "",
      Points: "",
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      offerImage: "",
      formError: {
        OfferName: "",
        Points: "",
        offerImage: "",
      },
    };
  }
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
      this.props.fetchApi(this.tblResponse);
      this.tblResponseExport.PageSize = -1;
      this.tblResponseExport.First = 1;
      this.tblResponseExport.IsActive = true;
      this.tblResponseExport.SearchValue = "";
      this.tblResponseExport.SortOrder = "ASC";
      this.props.exportingData(this.tblResponseExport);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.OfferData) {
      if (prevProps.OfferData !== this.props.OfferData) {
        // this.props.myProp has a different value
        if (this.props.OfferData) {
          var e = this.props.OfferData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.OfferId, this.state.switchAction);
    setTimeout(() => {
      this.props.fetchApi(this.tblResponse);
    }, 1500);
  };

  handleChange(event) {
    ;
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "OfferName":
        if (value.length < 1) {
          IsValid = false;
          errors.OfferName = "Please enter Offer name.";
        } else errors.OfferName = "";
        break;
      case "Points":
        if (value.length < 1) {
          IsValid = false;
          errors.Points = "Please enter Points";
        } else if (value.length >= 1) {
          if (value <= 0) {
            IsValid = true;
            errors.Points = "Points should be greater than zero";
          } else errors.Points = "";
        } else errors.Points = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const formData = {
        OfferId: this.state.OfferId,
        OfferName: this.state.OfferName,
        Points: this.state.Points,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.OfferId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.OfferId > 0 ? this.getCurrentDateMMDDYYY() : null,
      };
      this.props.createOffer(formData);
      if (!this.props.showDialog) {
        this.resetForm();
      }
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1500);
    }
  }

  resetForm() {
    this.setState({
      OfferId: "",
      OfferName: "",
      Points: "",
      offerImage: "",
      IsActive: true,
      formError: {
        OfferName: "",
        Points: "",
        offerImage: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    ;
    this.props.loadingData(true);
    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Offer" });
  }

  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      this.props.onHideShow(true);
      ;
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Offer",
        OfferId: rowData.OfferId == null ? 0 : rowData.OfferId,
        OfferName: rowData.OfferName,
        Points: rowData.Points,
        IsActive: rowData.IsActive,
        CreatedDate: rowData.CreatedDate,
      });
    };
    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        OfferId: rowData.OfferId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.OfferId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  // set Offer Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.offerImage = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              offerImage: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.offerImage = "Only image format is accepted.";
            this.setState({
              offerImage: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  render() {
    const renderFooter = () => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row">
        <div className="col-md-6 master_headhingcol">
          <h4>Offer Master</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>

          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              label="Add Offer"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
            <Button
              className="mr-2"
              label="Add Offer"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog}
            />
          )}
          <CSVLink
            data={this.props.List}
            headers={this.state.headers}
            filename={"Offer Report.csv"}
            target="_blank"
          >
            <button className="btn btn-success p-2 mr-2 buttone1">
              Export
            </button>
          </CSVLink>
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card">
          <DataTable
            value={this.props.OfferData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="OfferName" header="Offer Name" sortable></Column>
            <Column field="Points" header="Points" sortable></Column>
            <Column field="CreatedDt" header="Created Date" sortable></Column>
            {this.state.IsEdit === true ? (
              <Column className="text-right"
                field="Action"
                header="Action"
                style={{ width: "120px" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px" , display: "none"}}
            
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <form onSubmit={this.submitForm} autocomplete="off">
            <div className="row">
              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  name="OfferName"
                  autoComplete="false"
                  value={this.state.OfferName}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Offer Name*"
                />
                {this.state.formError.OfferName !== "" ? (
                  <div className="error">{this.state.formError.OfferName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-2">
                <TextField
                  type="number"
                  id="standard-basic"
                  size="small"
                  onChange={this.handleChange}
                  variant="outlined"
                  name="Points"
                  value={this.state.Points}
                  className="fullwidth"
                  label="Points*"
                />
                {this.state.formError.Points !== "" ? (
                  <div className="error">{this.state.formError.Points}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6 pt-3">
                <div className="mb-3">Upload Offer Image</div>

                <div className="mb-4">
                  <FileUpload
                    name="offerImage"
                    mode="basic"
                    url={
                      process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                    }
                    auto={true}
                    onUpload={this.setImagePath}
                    onError={this.onErrorImage}
                    accept="image/*"
                  />
                  {this.state.offerImage ? (
                    <div
                      className="p-fileupload-content mt-3 p-1"
                      style={{ display: "table", border: "1px solid #ccc" }}
                    >
                      <div>
                        <img
                          alt="pro.jfif"
                          role="presentation"
                          src={this.state.offerImage}
                          width="100"
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.state.formError.offerImage !== null ? (
                    <div className="error">
                      {this.state.formError.offerImage}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 pt-3">
                <Checkbox
                  name="IsActive"
                  checked={this.state.IsActive}
                  onClick={this.handleChange}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />{" "}
                <label>Is Active</label>
              </div>

              <div className="col-md-12 mt-3 mb-3 text-center">
                {this.state.IsEdit === true ? (
                  <Button label={this.state.buttonLabel} icon="pi pi-check" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12">
                Note: Fields marked as (*) are required
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    List: state.offer.exportitems == undefined ? [] : state.offer.exportitems,
    OfferData: state.offer.items,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.user.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(offerMasterActions.getAll(obj));
    },
    createOffer: (user) => dispatch(offerMasterActions.createOffer(user)),
    inActiveRow: (user, isActive) =>
      dispatch(offerMasterActions.inActiveRow(user, isActive)),
    onHideShow: (isbool) => dispatch(offerMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(offerMasterActions.onHideShowIsActive(isbool)),
    loadingData: (isLoding) =>
      dispatch(offerMasterActions.loadingData(isLoding)),
    exportingData: (obj) => dispatch(offerMasterActions.exportData(obj)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(offerMaster);
