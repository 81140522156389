import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { userMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import pic from "../../assets/images/man.png";
import Noimg from "../../assets/images/noimg.png";

class userApproval extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSort = this.onSort.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.state = {
      IsAdd: true,
      IsEdit: true,
      totalRecords: 0,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.UserData) {
      if (prevProps.UserData !== this.props.UserData) {
        // this.props.myProp has a different value
        if (this.props.UserData) {
          var e = this.props.UserData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  //to lazy load

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);
    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
    //this.resetForm();
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add User" });
  }

  actionBodyTemplate(rowData) {
    const approve = (e) => {
      this.props.approveUser(rowData.UserId);
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1000);
    };

    const reject = (e) => {
      this.props.rejectUser(rowData.UserId);
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1000);
    };

    const viewDetails = (e) => {
      ;
      // var rowData = e;
      this.tblResponse.DealerId =
        this.state.UserType > 1 ? this.state.DealerId : 0;
      //this.props.readRow(rowData.OrderBookingNo, false, this.tblResponse);
      this.setState({
        formHeading: "User Approval",
        UserName: rowData.UserName,
        Email: rowData.Email,
        RoleName: rowData.RoleName,
        MobileNo: rowData.MobileNo,
        GstNo: rowData.GstNo,
        GstCertificate: rowData.GstCertificate,
        PassbookDoc: rowData.PassbookDoc,
        PanNoDoc: rowData.PanNoDoc,
        AadharDoc1: rowData.AadharDoc1,
        WorkshopImg: rowData.WorkshopImg,
        RoleId: rowData.RoleId,
        City: rowData.City,
        State: rowData.State,
        Address: rowData.Address,
        AadharNo: rowData.AadharNo,
        PanNo: rowData.PanNo,
        Pincode: rowData.Pincode,
        District: rowData.District,
        IfscCode: rowData.IfscCode,
        AccountNo: rowData.AccountNo,
        UpiAddress: rowData.UpiAddress

      });
      this.props.onHideShow(true);
    };
    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Button
                className="btn m-1 btnapprove"
                icon="pi pi-check"
                onClick={approve}
              />
              <Button
                className="btn m-1 btnreject "
                icon="pi pi-times"
                onClick={reject}
              />
              <Button
                className="btn m-1 "
                icon="pi pi-eye"
                onClick={viewDetails}
              />
            </div>
          ) : (
            <div className="text-center"><Button
              className="btn"
              icon="pi pi-eye"
              onClick={viewDetails}
            /></div>
          )}
        </>
      );
    }
  }

  render() {
    const header = (
      <div className="table-header row">
        <div className="col-md-6 master_headhingcol">
          <h4>User Approval</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    );
    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card">
          <DataTable
            value={this.props.UserData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="UserName" header="User Name" sortable></Column>
            <Column field="RoleName" header="Role Name" sortable></Column>
            <Column field="Email" header="Email" sortable></Column>
            <Column field="MobileNo" header="Mobile Number" sortable></Column>
            <Column field="AadharNo" header="Aadhar Number" sortable></Column>
            <Column field="State" header="State" sortable></Column>
            <Column field="District" header="District" sortable></Column>
            <Column field="City" header="City" sortable></Column>

            <Column
              className="text-center action-approval"
              field="Action"
              header="Action"
              body={this.actionBodyTemplate}
            ></Column>

          </DataTable>
        </div>

        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <div className="mainblock">
            <div className="row">
              <div className="col-5">
                <div className="table-Bck ">
                  <table id="customers">
                    <tr>
                      <td className="tdnamesize">
                        <b>Name :</b>
                      </td>
                      <td>{this.state.UserName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>RoleName :</b>
                      </td>
                      <td>{this.state.RoleName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Email :</b>
                      </td>
                      <td>{this.state.Email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Mobile No :</b>
                      </td>
                      <td>{this.state.MobileNo}</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Aadhar No :</b>
                      </td>
                      <td>{this.state.AadharNo}</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Pan No :</b>
                      </td>
                      <td>{this.state.PanNo}</td>
                    </tr>

                    <tr>
                      <td>
                        <b>UPI ID: </b>
                      </td>
                      <td>{this.state.UpiAddress}</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Account No: </b>
                      </td>
                      <td>{this.state.AccountNo}</td>
                    </tr>


                    <tr>
                      <td>
                        <b>IFSC: </b>
                      </td>
                      <td>{this.state.IfscCode}</td>
                    </tr>



                    <tr>
                      <td>
                        <b>Gst no :</b>
                      </td>
                      <td>{this.state.GstNo}</td>
                    </tr>

                    <tr>
                      <td>
                        <b>State: </b>
                      </td>
                      <td>{this.state.State}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>District: </b>
                      </td>
                      <td>{this.state.District}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>City: </b>
                      </td>
                      <td>{this.state.City}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Pincode: </b>
                      </td>
                      <td>{this.state.Pincode}</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Address: </b>
                      </td>
                      <td>{this.state.Address}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="col-7">
                <div className="row">
                  <div col-12 className="DocHeadingblock">
                    <h3 className="DocHeading">
                      {" "}
                      <img className="manimg" src={pic} /> {this.state.RoleName}{" "}
                      Documents{" "}
                    </h3>
                  </div>
                </div>
                <br></br>

                <div className=" Docmainblock">
                  <div className="row">
                    <div className="col-4 Docblock">
                      <a href={this.state.WorkshopImg == '' ? true : this.state.WorkshopImg} target="_blank">
                        <img src={this.state.WorkshopImg == '' ? Noimg : this.state.WorkshopImg}></img>
                      </a>
                      <p>Work Shop </p>
                    </div>
                    <div className="col-4 Docblock">
                      <a href={this.state.AadharDoc1 == '' ? true : this.state.AadharDoc1} target="_blank">
                        <img src={this.state.AadharDoc1 == '' ? Noimg : this.state.AadharDoc1}></img>
                      </a>
                      <p>Addhar </p>
                    </div>
                    <div className="col-4 Docblock">
                      <a href={this.state.PanNoDoc == '' ? true : this.state.PanNoDoc} target="_blank">
                        <img src={this.state.PanNoDoc == '' ? Noimg : this.state.PanNoDoc}></img>
                      </a>
                      <p>Pan Card </p>
                    </div>
                    <div className="col-4 Docblock"
                      hidden={this.state.RoleId == 3 ? true : false}

                    >
                      <a href={this.state.GstCertificate == '' ? true : this.state.GstCertificate} target="_blank">
                        <img src={this.state.GstCertificate == '' ? Noimg : this.state.GstCertificate}></img>
                      </a>
                      <p>Gst Certificate </p>
                    </div>
                    <div className="col-4 Docblock">
                      <a href={this.state.PassbookDoc == '' ? true : this.state.PassbookDoc} target="_blank">
                        <img src={this.state.PassbookDoc == '' ? Noimg : this.state.PassbookDoc}></img>
                      </a>
                      <p>Passbook </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserData: state.user.items,
    showDialog: state.role.showDialog,
    showIsActiveDialog: state.user.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(userMasterActions.getAllForApproval(obj));
    },
    inActiveRow: (user, isActive) =>
      dispatch(userMasterActions.inActiveRow(user, isActive)),
    approveUser: (user, isActive) =>
      dispatch(userMasterActions.approveUser(user, isActive)),
    rejectUser: (user, isActive) =>
      dispatch(userMasterActions.rejectUser(user, isActive)),
    onHideShow: (isbool) => dispatch(userMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(userMasterActions.onHideShowIsActive(isbool)),
    loadingData: (isLoding) =>
      dispatch(userMasterActions.loadingData(isLoding)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(userApproval);
